import React from "react";
import { Link14Graphic1, Link14Graphic3 } from "../../../assets/svgs";
import Graphic from "../../../assets/images/Link14.svg"
const HUF = () => {
  return (
    <div className="bg-[#F1EBF3] max-w-[100vw] p-4  lg:px-4  relative">
      <div className="flex gap-2 items-center flex-wrap py-4">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill="#6F368B"
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill="#6F368B"
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
        </svg>
        <p className="text-[20px] font-semibold text-black text-left flex items-center gap-2">
          For Individuals & HUF
        </p>
      </div>
      <div className=" bg-white   overflow-hidden mt-4">
        <div className="overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0 text-[12px] md:text-[14px]">
            <thead className="bg-[#E9E1EE]">
              <tr>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Proof of Identity
                </th>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Proof of Address
                </th>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Proof of Date of Birth
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2  border align-top lg:min-w-[176px]">
                  <ol className="list-decimal pl-5 space-y-8 lg:min-w-[176px]">
                    <li>
                      Copy of any of the following documents bearing name of the
                      applicant as mentioned in the application:
                    </li>
                    <li>
                      a. Aadhaar Card issued by the Unique Identification
                      Authority of India; or
                    </li>
                    <li>b. Elector’s photo identity card; or</li>
                    <li>c. Driving License; or</li>
                    <li>d. Passport</li>
                    <li>
                      e. Ration card having photograph of the applicant; or
                    </li>

                    <Link14Graphic1
                      className={"ml-[-2rem] max-w-[90px] md:max-w-[139px]"}
                    />
                    <li>f. Arm’s license; or</li>
                    <li>
                      g. Photo identity card issued by the Central Government or
                      State Government or Public Sector Undertaking (in case of Transgender, Transgender certificate issued by District Magistrate); or
                    </li>
                    <li>
                      h. Pensioner card having photograph of the applicant; or
                    </li>
                    <li>
                      i. Central Government Health Scheme Card or Ex-Servicemen
                      Contributory Health Scheme photo card
                    </li>
                    <li>
                      ii) Certificate of identity in Original signed by a Member
                      of Parliament or Member of Legislative Assembly or
                      Municipal Councilor or a Gazetted officer
                    </li>
                    <li>
                      iii) Bank certificate in Original on letter head from the
                      branch (along with name and stamp of the issuing officer)
                      containing duly attested photograph and bank account
                      number of the applicant
                    </li>
                  </ol>
                </td>
                <td className="p-2  border align-top lg:min-w-[176px]">
                  <ol className="list-decimal pl-5 space-y-6 lg:min-w-[176px]">
                    <li>
                      Copy of any of the following documents bearing the address
                      mentioned in the application:
                    </li>
                    <li>
                      a. Aadhaar Card issued by the Unique Identification
                      Authority of India; or
                    </li>
                    <li>b. Elector’s photo identity card; or</li>
                    <li>c. Driving License; or</li>
                    <li>d. Passport</li>
                    <li>e. Passport of the spouse; or</li>
                    <li>
                      f. Post office passbook having address of the applicant;
                      or
                    </li>
                    <li>g. Latest property tax assessment order; or</li>
                    <li>
                      h. Domicile certificate issued by the Government; or
                    </li>
                    <li>
                      i. Allotment letter of accommodation issued by the Central
                      Government or State Government of not more than three
                      years old; or
                    </li>
                    <li>j. Property Registration Document; or</li>
                    <li>k. Pension payment order; or</li>
                    <li>
                      Copy of following documents of not more than three months
                      old:
                    </li>
                    <li>
                      l. Electricity Bill; m. Landline Telephone or Broadband
                      connection bill; n. Water Bill; o. Consumer gas connection
                      card or book or piped gas bill; p. Bank account statement
                      or as per Note 2; q. Depository account statement; r.
                      Credit card statement
                    </li>
                    <li>
                      iii) Certificate of address signed by a Member of
                      Parliament or Member of Legislative Assembly or Municipal
                      Councilor or a Gazetted officer
                    </li>
                  </ol>
                </td>
                <td className="p-2  border align-top">
                  <ol className="list-decimal pl-5 space-y-6 ">
                    <li>
                      Copy of any of the following documents bearing the name,
                      date, month and year of birth of the applicant as
                      mentioned in the application:
                    </li>
                    <li>
                      a. Aadhaar card issued by the Unique Identification
                      Authority of India; or
                    </li>
                    <li>b. Elector’s photo identity card; or</li>
                    <li>c. Driving License; or</li>
                    <li>d. Passport</li>
                    <li>
                      e. Matriculation certificate or Mark sheet of recognized
                      board; or
                    </li>
                    <li>
                      f. Birth certificate issued by the authority or any office
                      authorized to issue birth and death certificate by the
                      Registrar of Birth and Deaths or the Indian Consulate as
                      defined in clause (d) of sub-section (1) of section 2 of
                      the Citizenship Act, 1955 (57 of 1955); or
                    </li>
                    <li>
                      g. Photo identity card issued by the Central Government or
                      State Government or Public Sector Undertaking (in case of Transgender, Transgender certificate issued by District Magistrate); or
                    </li>
                    <li>
                      h. Domicile certificate issued by the Government; or
                    </li>
                    <li>
                      i. Central Government Health Service Scheme photo card or
                      Ex-servicemen Contributory Health Scheme photo card; or
                    </li>
                    <li>j. Pension payment order; or</li>
                    <li>
                      k. Marriage certificate issued by the Registrar of
                      Marriages; or
                    </li>
                    <li>
                      l. Affidavit sworn before a magistrate stating the date of
                      birth.
                    </li>
                    <li>iv) Employer certificate in original</li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-xs mt-4 space-y-2 px-4 pb-8">
          <div>
            <strong>Note:</strong> In case of Minor, any of the above mentioned
            documents as proof of identity and address of any of
            parents/guardians of such minor shall be deemed to be the proof of
            identity and address for the minor applicant.
          </div>
          <div>
            <strong>Note:</strong> For HUF, an affidavit made by the Karta of
            Hindu Undivided Family stating name, father’s name and address of
            all the coparceners on the date of application and copy of any of
            the above documents in the name of Karta of HUF is required as proof
            of identity, address and date of birth.
          </div>
          <div>
            <strong>Note:</strong> Proof of Address is required for residence
            address mentioned in item no. 7.
          </div>
          <div>
            <strong>Note:</strong> In case of an Indian citizen residing outside
            India, copy of Bank Account Statement in country of residence or
            copy of Non-resident External (NRE) bank account statements (not
            more than three months old) shall be the proof of address.
          </div>
        </div>
      </div>

<img src={Graphic} alt="protean"  className="absolute bottom-0 right-0 hidden lg:flex"/>
    </div>
  );
};

export default HUF;
