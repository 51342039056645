import React from "react";
import DynamicLinks from "../../../components/DynamicLink";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import DynamicLinksTwo from "../../../components/DyanmicLinksTwo";

const AOCodes = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 700;

  const linkData = [
    { text: "PAN AO Codes - International Taxation", href: "https://www.protean-tinpan.com/downloads/pan/2019-015-TIN-Annexure_3-International_AO_Code_Master_version_4.2.xlsx" },
    { text: "PAN AO codes-Non International Taxation (other than Mumbai Region)", href: "https://www.protean-tinpan.com/downloads/pan/PAN_AO_Code_Master_Other_than_Mumbai_V9.3.xlsx" },
    { text: "PAN AO codes-Non International Taxation (Mumbai Region)", href: "https://www.protean-tinpan.com/downloads/pan/PAN_AO_Code_Master_(Mumbai%20region)_Version%204.3.xlsx" },
    { text: "PAN AO Codes - Defence Personnel", href: "https://www.protean-tinpan.com/downloads/pan/PAN_AO_code_Defence.xlsx" },
    { text: "PAN AO codes for Pune Region", href: "https://www.protean-tinpan.com/downloads/pan/AO%20CODE%20for%20Pune%20Region.zip" },
  ];

  return (
    <section id="aocodes" className=" relative w-full flex flex-col  px-[22px]  lg:px-[72px] ">
      <div className="flex items-center mb-4">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill="#5B2E7E"
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill="#5B2E7E"
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill="#5B2E7E" />
        </svg>
        <h2 className="text-[24px] lg:text-[28px] font-semibold text-black ml-2">
          AO Codes
        </h2>
      </div>

      <DynamicLinksTwo
        linkData={linkData}
        listDir="flex-row"
        className="flex-row"
        defaultLinkTextColor="black"
        defaultLinkIconColor="black"
        defaultLinkBorderBottom="none"
        linkClassName="bg-[#F4F2F9] w-full hover:bg-[#E5E3ED] transition-colors duration-200 flex-row"
        linkStyle={{
          borderLeft: "5px solid #5B2E7E",
          padding: "12px",
          minHeight: "68px",
          background: "#F1EBF3",
        }}
      />

    </section>
  );
};

export default AOCodes;
