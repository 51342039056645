import React from "react";
import { Link14Graphic1 } from "../../../assets/svgs";

const Categories = () => {
  return (
    <div className="bg-[#FCEBE9] max-w-[100vw] p-4 lg:px-4  mt-4">
      <div className="flex gap-2 items-start flex-wrap md:flex-nowrap">
        <div className="mt-2">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#E43625"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#E43625"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#E43625" />
          </svg>
        </div>

        <div>
          <p className="text-[20px] font-semibold text-black text-left flex items-center gap-2">
            For Categories other than Individuals & HUF i.e. Firm, BOI, AOP, AOP
            (Trust), Local Authority, Company, Limited Liability
            Partnership,Artificial Juridical Person
          </p>
        </div>
      </div>
      <p className="font-light text-[14px] mt-4">
        List of documents which are acceptable as proof of identity and address
        for applicants other than individual and HUF is as below:
      </p>
      <p className="font-semibold py-3 mt-4">
        1.Having office of their own in India:
      </p>
      <div className=" bg-white   overflow-hidden ">
        <div className="overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0 text-[12px] md:text-[14px]">
            <thead className="bg-[#FAD7D3]">
              <tr>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Type of Applicant
                </th>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Document to be submitted
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2  border align-top lg:min-w-[176px]">
                  <ol className=" pl-5 space-y-6 lg:min-w-[176px]">
                    <li>Company</li>
                    <li>Partnership Firm</li>
                    <li>Limited Liability Partnership</li>

                    <li className="flex flex-col gap-3">
                      Association of Persons (Trust)
                    </li>

                    <li className="flex flex-col gap-3">
                      Association of Person, Body of Individuals, Local
                      Authority, or Artificial Juridical Person
                    </li>
                  </ol>
                </td>
                <td className="p-2  border align-top lg:min-w-[176px]">
                  <ol className=" pl-5 space-y-6 lg:min-w-[176px]">
                    <li>
                      Copy of certificate of registration issued by Registrar of
                      Companies.
                    </li>
                    <li>
                      Copy of certificate of registration issued by Registrar of
                      firms or Copy of Partnership Deed.
                    </li>
                    <li>
                      Copy of Certificate of Registration issued by the
                      Registrar of LLPs
                    </li>
                    <li>
                      Copy of trust deed or copy of certificate of registration
                      number issued by Charity Commissioner.
                    </li>
                    <li>
                      Copy of Agreement or copy of certificate of registration
                      number issued by charity commissioner or registrar of
                      cooperative society or any other competent authority or
                      any other document originating from any Central or State
                      Government Department establishing identity and address of
                      such person. an address issued by the employer.
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <p className="font-semibold py-3">
        2. Having no office of their own in India:
      </p>
      <table className="min-w-full bg-white border-separate border-spacing-0 text-[12px] md:text-[14px]">
        <thead className="bg-[#FAD7D3]">
          <tr>
            <th className="p-2 sm:p-4 border text-left text-[14px]">
              Type of Applicant
            </th>
            <th className="p-2 sm:p-4 border text-left text-[14px]">
              Document to be submitted
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2  border align-top lg:min-w-[176px]">
              <ol className=" pl-5 space-y-6 lg:min-w-[176px]">
                <li>Company</li>
                <li>Partnership Firm</li>
                <li>Limited Liability Partnership</li>

                <li className="flex flex-col gap-3">
                  Association of Persons (Trust)
                </li>

                <li className="flex flex-col gap-3">
                  Association of Person, Body of Individuals, Local Authority,
                  or Artificial Juridical Person
                </li>
              </ol>
            </td>
            <td className="p-2  border align-top lg:min-w-[176px]">
              <ol className=" pl-5 space-y-6 lg:min-w-[176px]">
                <li>
                  Copy of certificate of registration issued by Registrar of
                  Companies.
                </li>
                <li>
                  Copy of certificate of registration issued by Registrar of
                  firms or Copy of Partnership Deed.
                </li>
                <li>
                  Copy of Certificate of Registration issued by the Registrar of
                  LLPs
                </li>
                <li>
                  Copy of trust deed or copy of certificate of registration
                  number issued by Charity Commissioner.
                </li>
                <li>
                  Copy of Agreement or copy of certificate of registration
                  number issued by charity commissioner or registrar of
                  cooperative society or any other competent authority or any
                  other document originating from any Central or State
                  Government Department establishing identity and address of
                  such person. an address issued by the employer.
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Categories;
