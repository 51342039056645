import React from "react";
import { Helmet } from 'react-helmet';
import MovingCards from "./components/MovingCard";
import FifthSection from "./components/FifthSection";
import FirstSection from "./components/FirstSection";
import SecondSection from "./components/SecondSection";
import ThirdSection from "./components/ThirdSection";
import SixthSection from "./components/SixthSection";
import FAQComponent from "./components/FAQSection";
import ServicesSection from "./components/ServicesSection";

const Link1 = () => {
  return (
    <>
      <Helmet>
        <title>Protean PAN Services - PAN Card Application & Issuance</title>
        <meta name="description" content="Protean eGov Technologies Ltd, authorized by the Income Tax Department, simplifies PAN card application and processing. Apply online seamlessly for your PAN card." />
        <meta name="keywords" content="Protean, PAN, PAN Card, Protean PAN, Protean PAN Card, Protean PAN Card Application, Protean PAN Card Issuance, Protean PAN Card Application & Issuance, Protean PAN Card Application & Issuance, Protean PAN Card Application & Issuance, Protean PAN Card Application & Issuance" />
      </Helmet>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <MovingCards />
      <FifthSection />
      <SixthSection />
      <ServicesSection />
      <FAQComponent />
    </>
  );
};

export default Link1;
