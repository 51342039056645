import React from "react";
import Card from "./Card";

const cardDataArray = [
  {
    cardBackgroundColor: "rgba(84, 173, 71, 0.1)",
    title: "Search",
    svgColor: "#54AD47",
    linkData: [
      {
        text: "TIN Facilitation Centers cum PAN centers",
        textColor: "black",
        iconColor: "black",
        borderBottom: "1px solid black",
        maxWidth: "207px",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        href: "/tin-facilities#search-forms"
      },
      {
        text: "PAN Centers",
        textColor: "black",
        iconColor: "black",
        borderBottom: "1px solid black",
        maxWidth: "207px",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        href: "/pan-center#search-tabs-forms"
      },
      {
        text: "PAN Service Agency Centers",
        textColor: "black",
        iconColor: "black",
        borderBottom: "1px solid black",
        maxWidth: "207px",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        href: ""
      },
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "420px" : "300px", // Default min width for this card's links
    defaultMaxWidth: "61px", // Default max width for this card's links
  },
  {
    cardBackgroundColor: "#EAF4FA",
    title: "Contact us",
    svgColor: "#3091D0",
    linkData: [
      {
        text: "Rights",
        textColor: "black",
        iconColor: "black",
        borderBottom: "1px solid black",
        maxWidth: "207px",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        href: 'https://www.protean-tinpan.com/customer-care.html'
      },
      {
        text: "Complains/ Queries",
        textColor: "black",
        iconColor: "black",
        borderBottom: "1px solid black",
        maxWidth: "207px",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        href: "/customerfeedback#customer-care-forms"
      },
      {
        text: "Protean addresses",
        textColor: "black",
        iconColor: "black",
        borderBottom: "1px solid black",
        maxWidth: "207px",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        href: "/nsdl-addresses#branch-offices-grid"
      },
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "420px" : "300px", // Default min width for this card's links
  },
];

function SearchAndContactUsSection() {
  return (
    <section className="flex flex-col bg-white z-40">
      <div className="flex flex-wrap px-[22px] justify-center lg:px-[70px] gap-2 ">
        {cardDataArray.map((cardData, index) => (
          <Card
            key={index}
            {...cardData}
            style={{ padding: '25px 40px' }}
            className=" min-w-full md:min-w-[600px] lg:min-w-[630px] lg:max-w-[640px] flex-col m-[2px] "
            titleColor="black"
          />
        ))}
      </div>
    </section>
  );
}

export default SearchAndContactUsSection;
