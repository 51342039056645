const SearchData = [
    {
        label: "Protean PAN Services - PAN Card Application & Issuance",
        path: "/",
        keywords: ["online pan application", "nsdl pan card online", "apply pan card online", "e filing pan card", "tin nsdl pan application", "online pan verification", "e tds filing", "oltas tax payment", "form 49A", "tan application"],
        description: "Protean eGov Technologies Ltd, authorized by the Income Tax Department, simplifies PAN card application and processing. Apply online seamlessly for your PAN card."
    },
    {
        label: "Apply for PAN Card, Track Status, Change, Correction, Reprint, Docs",
        path: "/services/pan/pan-index",
        keywords: ["apply pan card", "track pan status", "change pan details", "correct pan details", "reprint pan", "pan documents"],
        description: "Apply for a PAN card, track status, change or correct details, reprint, and know required documents. Get PAN Services."
    },
    {
        label: "e-TDS/TCS Return Preparation Utility (RPU)",
        path: "/services/etds-etcs",
        keywords: ["e-TDS", "TCS", "return preparation utility", "RPU", "quarterly returns"],
        description: "Detail information on e-TDS/TCS services for return prepration utility, RPU for quarterly returns"
    },
    {
        label: "Protean TIN PAN Privacy Policy - Data Privacy and Security",
        path: "/privacy-policy",
        keywords: ["tin facilitation centre", "income tax payment online", "nsdl tax information network website", "e tax payment system", "pan card online application"],
        description: "Discover how Protean upholds data protection and information security. Ensuring your data privacy is our top priority."
    },
    {
        label: "eTDS/eTCS Services - Compliance & Filing Solutions | Protean",
        path: "/services/etds-etcs/etds-index",
        keywords: ["tds online payment", "income tax tds online payment", "e tds return", "e tds", "e tds payment", "e tds filing", "e tds payment online", "tds e payment online", "online tds deposited", "tds tcs online payment"],
        description: "Explore Protean's eTDS/eTCS services for compliance and tax filing solutions. Access resources and support for accurate and timely tax submissions."
    },
    {
        label: "PAN Introduction: Overview and Importance of PAN Card | Protean",
        path: "/services/pan/pan-introduction",
        keywords: ["PAN card", "importance of PAN", "PAN system", "taxpayers in India"],
        description: "Learn about PAN card, its importance, and how it works. Get an introduction to the PAN system and its benefits for taxpayers in India."
    },
    {
        label: "Find PAN Center: PAN Service Centers",
        path: "/pan-center",
        keywords: ["PAN center", "PAN service centers", "PAN application", "PAN change", "PAN correction"],
        description: "Locate your nearest PAN center for application, change or correction, and other PAN services. Access PAN service centers nationwide."
    },
    {
        label: "eTDS Download Regular - Protean Pan & Tan Services",
        path: "/downloads/e-tds/eTDS-download-regular",
        keywords: ["eTDS download", "eTDS software", "eTDS forms", "eTDS filing"],
        description: "Download eTDS software and forms. Get eTDS filing resources from Protean Tinpan. Start your eTDS download now!"
    },
    {
        label: "e-TDS/TCS File Validation Utility",
        path: "/services/etds-etcs/etds-file-validation-utility",
        keywords: ["E tds", "e tds return", "e tds filling", "e tds payment", "income tax tds online payment", "tds payment online payment", "tds tcs online payment", "tds e payment online", "tds e payment"],
        description: "Detail information on e-TDS/TCS services for file validation utility for quarterly returns"
    },
    {
        label: "Online PAN Verification Service",
        path: "/services/online-pan-verification/pan-verification-overview",
        keywords: ["online pan verification", "pan number verification", "verify pan card", "pan verify online", "online pan verification nsdl", "online pan verification by pan no", "pan card verification online", "pan verification"],
        description: "Quick and secure online PAN verification process. Ensure accurate and efficient PAN validation with our trusted service."
    },
    {
        label: "TAN Services Overview - Apply & Manage TAN | Protean",
        path: "/services/tan/tan-introduction",
        keywords: ["tan application status", "tan application", "tan application online", "online tan application", "tan application form", "tan application form download", "tan application form online", "tan application form pdf"],
        description: "Learn about TAN services with Protean. Apply for a TAN, manage your details, and ensure compliance with tax regulations. Comprehensive TAN service guide."
    },
    {
        label: "Documents Required for PAN Card - Protean Pan & Tan Services",
        path: "/services/pan/documents-req",
        keywords: ["PAN card documents", "PAN application documents", "documents for PAN"],
        description: "Discover essential documents needed for PAN card application. Get expert guidance from Protean Tinpan. Learn more now!"
    },
    {
        label: "Customer Feedback Form - Protean Pan & Tan Services",
        path: "/customerfeedback",
        keywords: ["customer feedback", "feedback form", "contact us"],
        description: "Submit your feedback with our customer feedback form. Contact us now!"
    },
    {
        label: "TIN Facilities: Services, Registration, and Application",
        path: "/tin-facilities",
        keywords: ["TIN facilities", "TIN registration", "TIN application", "Tax Information Network"],
        description: "Explore TIN facilities including registration, application, and services. Access comprehensive Tax Information Network support and resources."
    },
    {
        label: "Protean Addresses for PAN Centres - Protean Pan & Tan Services",
        path: "/nsdl-addresses",
        keywords: ["pan call centre", "tds call centre", "tax information network", "nsdl call centre", "pan card call centre", "tin nsdl call centre", "pan call centre number", "nsdl pan card call centre number", "tin facilitation"],
        description: "Locate Protean addresses for PAN card services. Get contact details and directions."
    },
    {
        label: "Track PAN Application Status - Step-by-Step Guide | Protean",
        path: "/guided/guide-status-track",
        keywords: ["tin call centre", "nsdl pan card status", "check pan card status", "track pan card status", "pan card application status", "pan card status by name and date of birth", "uti pan card status", "track pan card"],
        description: "Use Protean's guide to track the status of your PAN application. Follow our step-by-step instructions for real-time updates on your PAN card processing."
    },
    {
        label: "TAN Downloads - Protean Pan & Tan Services",
        path: "/services/tan/tan-downloads",
        keywords: ["form 49 b", "correction in tan data", "tan application form online", "pan tan application status", "tan application form online", "tan application form download", "tax deduction account number tan", "tan 49b"],
        description: "Download TAN-related forms and documents. Access resources from Protean Tinpan for TAN registration. Get downloads now!"
    },
    {
        label: "About Us - Protean Pan & Tan Services",
        path: "/about-us",
        keywords: ["online tan application", "online pan card apply", "online pan verification", "tax information network pan card", "tan application online", "form 49a pan card", "oltas tax payment", "what is form 24g", "oltas"],
        description: "Learn about Protean Tinpan, your trusted PAN and TAN service provider. Discover our mission and commitment. Read more!"
    }
];

export default SearchData;