import React from 'react';
import { CallIcon, WhatsAppIcon } from '../../../assets/svgs';

const PANCallCenter = () => {
  const contactData = [
    {
      title: 'PAN/TDS Call Centre of Tax Information Network (TIN) managed by Protean',
      details: [
        { label: 'Address', value: 'Protean eGov Technologies Limited, 4th Floor, Sapphire Chambers, Near Axis Bank Baner, Baner Road, Baner, Pune – 411045' },
        { label: 'Landmark', value: 'Near Axis Bank Baner' },
        { label: 'Business Hours', value: '(From 9:30 AM to 6:00 PM Monday to Saturday)' },
      ],
      additionalInfo: [
        {
          icon: <CallIcon />,
          label: '',
          value: '020 - 27218080 (From 7:00 AM to 11:00 PM, Monday to Sunday)',
          color: '#6F368B',
        },
        {
          icon: <CallIcon />,
          label: 'Smartflo IVR Number',
          value: '08069708080',
          color: '#6F368B',
        },
        {
          icon: <WhatsAppIcon />,
          label: 'Whatsapp Business',
          value: '8096078080',
          color: '#6F368B',
        },
      ],
      email: [

        { label: 'Email', value: 'tininfo@proteantech.in' },
      ],
      note: 'You may also check the status of your PAN/TAN application at TIN website - click here or SMS PTNPAN 15 digit acknowledgment number to 57575 to know the status of your application. We appreciate your support and cooperation.',
      noteColor: '#D95A36',
    },
    {
      title: 'Head Office',
      details: [
        { label: 'Address', value: 'Times Tower, 1st Floor, Kamala Mills Compound, Senapati Bapat Marg, Lower Parel, Mumbai, Maharashtra, PIN - 400013.' },
      ],
    },
  ];

  return (
    <section className="lg:px-[70px] w-full ">
      <div className="flex flex-col w-full">
        {contactData.map((section, index) => (
          <div key={index} className="w-full p-4">
            <div className="flex items-center gap-2 mb-4 flex-wrap">
              <svg
                width="34"
                height="17"
                viewBox="0 0 34 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                  fill="#6F368B"
                />
                <path
                  d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                  fill="#6F368B"
                />
                <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
              </svg>
              <h2 className="text-2xl font-semibold">{section.title}</h2>
            </div>
            <div className="space-y-2">
              {section.details.map((detail, idx) => (
                <p key={idx} className="text-gray-800 gap-2 text-[15px]">
                  <span className="font-semibold"> {detail.label}:</span> {detail.value}
                </p>
              ))}
              {section.additionalInfo && section.additionalInfo.map((info, idx) => (
                <p key={idx} className="text-[15px] flex items-center gap-2">
                  {info.icon}
                  <span style={{ color: info.color }}>{info.label} - {info.value}</span>
                </p>
              ))}

              {section.email && section.email.length > 0 && section.email.map((email, idx) => (
                <p key={idx} className="text-gray-800 gap-2 text-[15px]">
                  <span className="font-semibold">{email.label}:</span> {email.value}
                </p>
              ))}

              {section.note && (
                <p className="text-[15px] mt-2" style={{ color: section.noteColor }}>
                  <span className="font-semibold">Note:</span> {section.note}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PANCallCenter;
